import React, { useEffect, useState } from 'react';
import { Modal, LegacyStack, Text } from '@shopify/polaris';
import horseLogo from 'images/horse-logo-110.png';
import moreMoneyIcon from 'images/more-money-icon.png';
import purchaseOrderIcon from 'images/purchase-order-icon.png';
import { getReadBanner, createReadBanner } from '../../../api_utils/requests';
import { useNavigate } from 'react-router-dom';

const steps = {
  1: {
    header: "Welcome to Horse!",
    body: "Horse gives you magical inventory powers so that you can focus on running your business.",
    logo: horseLogo,
  },
  2: {
    header: "No configuration required",
    body: "Just wait for Horse to import your products and locations and then you'll be ready to start earning more money!",
    logo: moreMoneyIcon,
  },
  3: {
    header: "Get started with your first purchase order",
    body: "Start by making a trial purchase order or simply check out your sales trends.",
    logo: purchaseOrderIcon,
  },
};

function Onboarding() {
  const navigate = useNavigate();
  const [alreadySeen, setAlreadySeen] = useState(true);
  useEffect(() => {
    const fetchBanner = async () => {
      getReadBanner("Onboarding").catch((e) => {
        if (e.status === 404) {
          setAlreadySeen(false);
        }
      });
    }
    fetchBanner();
  }, []);

  const dismissBanner = () => {
    setAlreadySeen(true)
    createReadBanner("Onboarding");
  };

  const [onboardingStep, setOnboardingStep] = useState(1);
  let actions;
  if (onboardingStep === Object.keys(steps).length) {
    actions = {
      secondaryActions: {
        content: 'Sales trends',
        onAction: () => {
          dismissBanner();
          navigate("/sales_trends");
        },
      },
      primaryAction: {
        content: 'Make a purchase order',
        onAction: () => {
          dismissBanner();
          navigate("/purchase_orders/new");
        },
      },
    };
  } else {
    actions = {
      secondaryActions: {
        content: 'Next',
        onAction: () => { setOnboardingStep(onboardingStep + 1); },
      },
    };
  }

  return (
    <Modal
      onClose={dismissBanner}
      open={!alreadySeen}
      small
      titleHidden
      { ...{ ...actions }}
    >
      <Modal.Section>
        <LegacyStack distribution='center'>
          <img height={ 110 } src={steps[onboardingStep].logo} style={{ margin: "1em" }}/>
        </LegacyStack>
        <LegacyStack distribution='center'>
          <Text as="h2" variant="headingMd">
            { steps[onboardingStep].header}
          </Text>
        </LegacyStack>
        <LegacyStack distribution='center'>
          <p className='text-center'>
            { steps[onboardingStep].body }
          </p>
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );
}

export default Onboarding;

import React from 'react';
import {
  LegacyCard,
  LegacyStack,
  Text,
} from '@shopify/polaris';

export const ProductAvailability = (
  {
    channels
  }: {
    channels: {
      friendly_name: string,
      shopify_domain: string,
      available: boolean,
    }[],
  }
): JSX.Element => (
  <LegacyCard sectioned>
    <LegacyStack distribution="fillEvenly">
      <LegacyStack.Item>
        <h2 className='Polaris-Heading'>Availability</h2>
      </LegacyStack.Item>
    </LegacyStack>
    {channels.map((item, idx) => {
      const {
        friendly_name, available, shopify_domain,
      } = item;
      return (
        <LegacyCard.Section key={`${idx}-${friendly_name}`}>
          <div>
            <LegacyStack distribution="fillEvenly" alignment="center">
              <LegacyStack.Item>
                <h4>
                  <Text as="strong">{friendly_name}</Text>
                </h4>
                <Text as="span" tone="subdued">
                  {shopify_domain}
                </Text>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <span className={`dot ${available ? "availableDot" : "unavailableDot"}`}>&nbsp;</span>
              </LegacyStack.Item>
            </LegacyStack>
          </div>
        </LegacyCard.Section>
      );
    })}
  </LegacyCard>
);

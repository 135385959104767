import React, { useCallback, useRef } from "react";
import { FormLayout, Label, Card, Link, Select, TextField, BlockStack, Text, Box } from "@shopify/polaris";
import type { DateRangeFilter, IPurchaseOrder } from "../../api_utils/types";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import { formatDateStringToMMDYY } from "../common/CustomDatePicker/utils";
import { assertString } from "../../helper_functions/utils";

export default function PurchaseOrderEditCard({
  purchaseOrder,
  setPurchaseOrder,
  arrivalAndDepletionDate,
  setArrivalAndDepletionDate,
  setIsDirty,
  currencies,
}: {
  readonly purchaseOrder: IPurchaseOrder;
  readonly setPurchaseOrder: (purchaseOrder: IPurchaseOrder) => void;
  readonly arrivalAndDepletionDate: DateRangeFilter;
  readonly setArrivalAndDepletionDate: (arrivalAndDepletionDate: DateRangeFilter) => void;
  readonly setIsDirty: (isDirty: boolean) => void;
  readonly currencies: string[];
}): React.ReactElement {
  const firstSelectableDate = useRef(new Date());

  const handleCurrencyChange = useCallback(
    (currency: string) => {
      setPurchaseOrder({ ...purchaseOrder, currency });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, setIsDirty],
  );

  const handleLabelChange = useCallback(
    (label: string) => {
      setPurchaseOrder({ ...purchaseOrder, label });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, setIsDirty],
  );

  const handleArrivalAndDepletionDateChange = useCallback(
    (newArrivalAndDepletionDate: DateRangeFilter) => {
      setArrivalAndDepletionDate(newArrivalAndDepletionDate);
      setIsDirty(true);
    },
    [setArrivalAndDepletionDate, setIsDirty],
  );

  const setDate = useCallback(
    (newArrivalDate: Date) => {
      let newEnd = arrivalAndDepletionDate.end;
      if (arrivalAndDepletionDate.end < newArrivalDate) {
        newEnd = new Date(newArrivalDate.valueOf());
        newEnd.setDate(newArrivalDate.getDate() + 30);
      }
      handleArrivalAndDepletionDateChange({ start: newArrivalDate, end: newEnd });
    },
    [arrivalAndDepletionDate, handleArrivalAndDepletionDateChange],
  );

  return (
    <Card>
      <BlockStack gap="400">
        {purchaseOrder.supplier ? (
          <BlockStack gap="200">
            <Text as="h3" variant="headingSm">
              Supplier
            </Text>
            <Label id="supplier-link">
              <Link url={`/suppliers/${purchaseOrder.supplier.id}`}>{purchaseOrder.supplier.name}</Link>
            </Label>
          </BlockStack>
        ) : (
          <BlockStack gap="200">
            <Text as="h3" variant="headingSm">
              Vendor
            </Text>
            <Label id="vendor-name">{purchaseOrder.vendor}</Label>
          </BlockStack>
        )}
        <BlockStack gap="200">
          <Text as="h3" variant="headingSm">
            Destination
          </Text>
          <Label id="destination-link">
            <Link url={`/horse_locations/${purchaseOrder.horse_location_id}`}>{purchaseOrder.destination}</Link>
          </Label>
        </BlockStack>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              autoComplete="off"
              label="Label"
              onChange={handleLabelChange}
              placeholder={purchaseOrder.id.toString()}
              value={assertString(purchaseOrder.label)}
            />
            <Select
              id="currencies"
              label="Currency"
              onChange={handleCurrencyChange}
              options={currencies}
              value={purchaseOrder.currency}
            />
            <CustomDatePicker
              date={arrivalAndDepletionDate.start}
              firstSelectableDate={firstSelectableDate.current}
              label="Estimated arrival date"
              placeholder={formatDateStringToMMDYY(arrivalAndDepletionDate.start)}
              setDate={setDate}
            />
          </FormLayout.Group>
        </FormLayout>
      </BlockStack>
    </Card>
  );
}

import React from 'react';
import {
  Modal,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

function DeleteConfirmationDialog({
  itemId, deleteItem, gotoPath, title, content, active, setActive
}: {
  readonly itemId: number,
  readonly deleteItem: (itemId: number) => Promise<void>,
  readonly gotoPath: string,
  readonly title: string,
  readonly content: string,
  readonly active: boolean,
  readonly setActive: (newState: boolean) => void
}): React.JSX.Element {
  const navigate = useNavigate();
  const handleClose = (): void => {
    setActive(false);
  };

  const handleDelete = (): void => {
    setActive(false);
    deleteItem(itemId).then(() => {
      navigate(gotoPath);
    });
  };

  return (
    <Modal
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: 'Delete',
        destructive: true,
        onAction: handleDelete,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleClose,
        },
      ]}
      title={title}
    >
      <Modal.Section>
        {content}
      </Modal.Section>
    </Modal>
  );
}

export default DeleteConfirmationDialog;

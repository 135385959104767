import React, { useState, useCallback } from 'react';
import {
  Button, ChoiceList, Popover, LegacyStack, Tag, Text,
} from '@shopify/polaris';
import { Option } from '../../../api_utils/types';

function PopoverWithCheckList({
  defaultTitle,
  onChange,
  onValueRemoved,
  choices,
  selected,
  allowMultiple=true,
}: {
  defaultTitle: string,
  onChange: (newSelectedValues: string[]) => void,
  onValueRemoved: (value: string) => void,
  choices: Option[],
  selected: string[],
  allowMultiple?: boolean,
}) {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((active) => !active),
    [],
  );

  const activator = (
    <Button size="large" onClick={togglePopoverActive} disclosure variant='secondary'>
      {defaultTitle}
    </Button>
  );

  return (
    <LegacyStack vertical distribution='center'>
      <LegacyStack.Item>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
          ariaHaspopup={false}
          preferredAlignment="left"
          sectioned
        >
          <ChoiceList
            title=""
            allowMultiple={allowMultiple}
            choices={choices}
            selected={selected}
            onChange={onChange}
          />
        </Popover>
      </LegacyStack.Item>
      {
        selected.length > 0 && <LegacyStack.Item>
          <LegacyStack spacing='extraTight'>
            {
              selected.map((selectedValue) => {
                return <LegacyStack.Item key={selectedValue}>
                    <Tag key={selectedValue} onRemove={() => onValueRemoved(selectedValue)}>
                    <Text as="span" variant='bodyMd'>{ choices.find((option) => option.value === selectedValue)?.label }</Text>
                  </Tag>
                </LegacyStack.Item>;
              })
            }
          </LegacyStack>
        </LegacyStack.Item>
      }
    </LegacyStack>
  );
}

export default PopoverWithCheckList;

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  Button,
  LegacyCard,
  FormLayout,
  Icon,
  Page,
  LegacyStack,
  TextField,
  Layout,
  Select,
  PageActions,
  IndexTable,
  EmptyState,
} from '@shopify/polaris';
import {
  MobileCancelMajor,
  ImportMinor,
  ExportMinor,
} from '@shopify/polaris-icons';
import {
  ContextualSaveBar,
  Loading,
} from '@shopify/app-bridge-react';
import AddVariantDialog from '../common/AddVariantDialog';
import DeleteConfirmationDialog from '../common/DeleteConfirmationDialog';
import HorseVariant from '../common/HorseVariant/HorseVariant';
import {
  deleteSupplier,
  getSupplierHorseVariants,
  removeSupplierHorseVariant,
  removeAllSupplierHorseVariants,
  updateSupplierSelection,
  updateSupplier,
  importSupplierHorseVariants,
  downloadFile,
  getSupplier,
  useUser,
  useCurrencies,
} from '../../api_utils/requests';
import { ErrorBanner } from '../common/ErrorBanner';
import { ImportFile } from '../common/ImportFile';
import { Footer } from '../common/Footer';
import { assertString, getCurrencySymbol, getIdFromPath, softAssertNumber } from '../../helper_functions/utils';
import type { QueryParams, Supplier, SupplierHorseVariant } from '../../api_utils/types';
import type { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import type { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { SupplierShowPageSkeleton } from '../common/skeletons';
import { SUPPLIERS_HELP_PAGE_URL } from '../../constants';
import Toast from '../common/Toast';

interface SupplierHorseVariants {number: SupplierHorseVariant}

const formatSupplierToSave = ({name, email, currency, delivery_lead_days}: Supplier): Partial<Supplier> => (
  {
    name,
    email,
    currency,
    delivery_lead_days: delivery_lead_days === 0 ? undefined : delivery_lead_days,
  }
);

const formatSupplierHorseVariants = (supplierHorseVariants: SupplierHorseVariant[]): SupplierHorseVariants => (
  supplierHorseVariants.reduce<SupplierHorseVariants>((map, horseVariant) => {
    map[horseVariant.id] = horseVariant;
    return map;
  }, {})
);

const columnNames: NonEmptyArray<IndexTableHeading> = [
  { title: 'Variant' },
  { title: 'Minimum order quantity' },
  { title: 'Pack size' },
  { title: 'Supplier SKU' },
  { title: 'Supplier cost' },
  { title: '' },
];

const emptyState = (
  <EmptyState heading='No variants added' image=''>
    <p>You haven't added any variants to your supplier yet.</p>
  </EmptyState>
);

function ShowSupplier(): React.JSX.Element {
  const supplierId = useMemo(() => getIdFromPath(), []);

  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);

  const [originalSupplier, setOriginalSupplier] = useState<Supplier>();
  const [supplier, setSupplier] = useState<Supplier>({} as Supplier);
  const [originalSupplierHorseVariants, setOriginalSupplierHorseVariants] = useState<SupplierHorseVariants>({} as SupplierHorseVariants);
  const [supplierHorseVariants, setSupplierHorseVariants] = useState<SupplierHorseVariants>({} as SupplierHorseVariants);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const [addVariantModalActive, setAddVariantModalActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);
  // Pagination for SupplierHorseVariants
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrev, setHasPrev] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [pageNum, setPageNum] = useState<number>(Number(urlParams.get('page')) || 1);

  const setSupplierHorseVariantsData = (newSupplierHorseVariants: SupplierHorseVariant[], hasNext: boolean, hasPrev: boolean): void => {
    const formattedSupplierHorseVariants = formatSupplierHorseVariants(newSupplierHorseVariants || []);
    setSupplierHorseVariants(formattedSupplierHorseVariants);
    setOriginalSupplierHorseVariants(formattedSupplierHorseVariants);
    setHasNext(hasNext);
    setHasPrev(hasPrev);
  };
  
  const { data: {currency: userCurrency}} = useUser();

  const { data: currencies} = useCurrencies();

  useEffect(() => {
    Promise.all([
      getSupplier(supplierId),
      getSupplierHorseVariants(supplierId, { page: pageNum }),
    ]).then(([
      {supplier},
      {
        rows: newSupplierHorseVariants,
        hasNext,
        hasPrev
      }
    ]) => {
      setOriginalSupplier(supplier);
      setSupplier(supplier);
      setSupplierHorseVariantsData(newSupplierHorseVariants, hasNext, hasPrev);

      setPageLoading(false);
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
  }, [supplierId, pageNum]);

  const handleVariantUpdate = useCallback(() => {
    getSupplierHorseVariants(supplierId, { page: pageNum }).then(({
      rows: newSupplierHorseVariants,
      hasNext,
      hasPrev,
    }) => {
      setSupplierHorseVariantsData(newSupplierHorseVariants, hasNext, hasPrev);
    });
  }, [supplierId, pageNum]);

  const handleSupplierChange = useCallback((value: string, key: string): void => {
    setSupplier((prevSupplier) => {
      return {...prevSupplier, [key]: value};
    });
    setIsDirty(true);
  }, []);

  const handleSaveSupplier = useCallback(() => {
    setLoading(true);

    const formattedSupplier = formatSupplierToSave(supplier);
    const payload = {
      supplier: {
        ...formattedSupplier,
        supplier_horse_variants_attributes: supplierHorseVariants,
      },
    };
    updateSupplier(supplierId, payload)
      .then((res) => {
        setToastMessage('Save successful');
        setIsDirty(false);
        setSupplier(res.supplier);
        setOriginalSupplier(res.supplier);
        // Refetch supplier horse variants
        getSupplierHorseVariants(supplierId, { page: pageNum }).then(({
          rows: newSupplierHorseVariants,
          hasNext,
          hasPrev,
        }) => {
          setSupplierHorseVariantsData(newSupplierHorseVariants, hasNext, hasPrev);
        });
      })
      .catch((err) => {
        Rollbar.error(err);
        const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [supplier, supplierHorseVariants, supplierId, pageNum]);

  const handleHorseVariantsChange = useCallback((variantId: number, value: number | string, key: string) => {
    const newSupplierHorseVariants = { ...supplierHorseVariants };
    newSupplierHorseVariants[variantId] = { ...newSupplierHorseVariants[variantId], [key]: value } as SupplierHorseVariant;
    setSupplierHorseVariants(newSupplierHorseVariants);
    setIsDirty(true);
  }, [supplierHorseVariants]);

  const removeAllVariants = useCallback(() => {
    setLoading(true);
    removeAllSupplierHorseVariants(supplierId).then(() => {
      setSupplierHorseVariants({} as SupplierHorseVariants);
      setOriginalSupplierHorseVariants({} as SupplierHorseVariants);
    }).catch((err) => {
      Rollbar.error(err);
      const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
      setErrorMessage(errorMessage);
    }).finally(() => {
      setLoading(false);
    });
  }, [supplierId]);

  const removeVariant = useCallback((id: number) => {
    setLoading(true);
    removeSupplierHorseVariant(id)
      .then(() => {
        setToastMessage('Variant removed');
        // Refetch supplier horse variants
        getSupplierHorseVariants(supplierId, { page: pageNum }).then(({
          rows: newSupplierHorseVariants,
          hasNext,
          hasPrev,
        }) => {
          setSupplierHorseVariantsData(newSupplierHorseVariants, hasNext, hasPrev);
        });
      })
      .catch((err) => {
        Rollbar.error(err);
        const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
        setErrorMessage(errorMessage);
        setSupplierHorseVariants(originalSupplierHorseVariants);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [supplierId, pageNum, originalSupplierHorseVariants]);

  const handleDiscardAction = useCallback(() => {
    setSupplier(originalSupplier);
    setSupplierHorseVariants(originalSupplierHorseVariants);
    setIsDirty(false);
  }, [originalSupplier, originalSupplierHorseVariants]);

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setLoading(true);
    setPageNum(newPageNum);
    getSupplierHorseVariants(supplierId, { page: newPageNum }).then(({
      rows: newSupplierHorseVariants,
      hasNext,
      hasPrev,
    }) => {
      setSupplierHorseVariantsData(newSupplierHorseVariants, hasNext, hasPrev);
      setLoading(false);

      document.getElementsByClassName('Polaris-IndexTable__Table')[0]?.scrollIntoView();
    });
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const pageMarkup = supplier && (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{ onAction: handleDiscardAction }}
        saveAction={{ onAction: handleSaveSupplier }}
        visible={isDirty}
      />
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <ImportFile
        apiCallMethod={async (formData: object) => importSupplierHorseVariants(supplierId, formData)}
        modalIsOpen={importCSVModalIsOpen}
        setApiResponse={(supplier_horse_variants: SupplierHorseVariant[]) => {
          const newSupplierHorseVariants = formatSupplierHorseVariants(supplier_horse_variants)
          setSupplierHorseVariants(newSupplierHorseVariants);
          setOriginalSupplierHorseVariants(newSupplierHorseVariants);
        }}
        setErrorMessage={setErrorMessage}
        setModalIsOpen={setImportCSVModalIsOpen}
        setToastMessage={setToastMessage}
        title="Import supplier CSV"
      />

      <Page
        actionGroups={[
          {
            title: 'More actions',
            actions: [
              {
                content: 'Export CSV',
                onAction: (): void => {
                  const supplierName = supplier.name || supplierId;
                  const filename = `Horse Supplier ${supplierName}.csv`;
                  downloadFile(`/suppliers/${supplierId}.csv${window.location.search}`, filename);
                },
                icon: ExportMinor,
              },
              {
                content: 'Import CSV',
                onAction: () => { setImportCSVModalIsOpen(true); },
                icon: ImportMinor,
              },
            ],
          },
        ]}
        backAction={{
          content: 'Suppliers',
          url: '/suppliers'
        }}
        title={supplier.name}
      >
        <Layout>
          {errorMessage ? <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
          </Layout.Section> : null}
          <Layout.Section>
            <LegacyCard>
              <LegacyCard.Section>
                <FormLayout>
                  <TextField
                    autoComplete='on'
                    id='name'
                    label='Name'
                    onChange={handleSupplierChange}
                    value={supplier.name}
                  />
                  <TextField
                    autoComplete='on'
                    id='email'
                    label='Contact email'
                    onChange={handleSupplierChange}
                    placeholder='contact@example.com'
                    value={assertString(supplier.email)}
                  />
                  <FormLayout.Group condensed>
                    <TextField
                      autoComplete='off'
                      id='delivery_lead_days'
                      label='Delivery lead days'
                      min={0}
                      onChange={handleSupplierChange}
                      suffix='days'
                      type='integer'
                      value={assertString(supplier.delivery_lead_days)}
                    />
                    <Select
                      id='currency'
                      label="Currency"
                      onChange={handleSupplierChange}
                      options={currencies}
                      value={supplier.currency || userCurrency}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
              <LegacyCard title="Variants">
                <LegacyCard.Section>
                  <LegacyStack distribution="center">
                    <AddVariantDialog
                      active={addVariantModalActive}
                      handleVariantUpdate={handleVariantUpdate}
                      primary
                      removeAllVariants={removeAllVariants}
                      selected={Object.values(supplierHorseVariants).map(({horse_variant_id}) => horse_variant_id)}
                      selectionKey='supplier_horse_variants_attributes'
                      setActive={setAddVariantModalActive}
                      setErrorMessage={setErrorMessage}
                      updateSelection={async (payload: object, queryParams: QueryParams) => updateSupplierSelection(supplierId, payload, queryParams)}
                    />
                  </LegacyStack>
                </LegacyCard.Section>

                <IndexTable
                  emptyState={emptyState}
                  headings={columnNames}
                  itemCount={Object.keys(supplierHorseVariants).length}
                  pagination={{
                    hasPrevious: hasPrev,
                    previousKeys: [37],
                    onPrevious: prevPage,
                    hasNext: hasNext,
                    nextKeys: [39],
                    onNext: nextPage,
                  }}
                  selectable={false}
                >
                  {Object.values(supplierHorseVariants).map((variant: SupplierHorseVariant, index) => (
                    <IndexTable.Row
                      id={variant.id.toString()}
                      key={variant.id}
                      position={index}
                    >
                      <IndexTable.Cell>
                        <div className="medium_hv">
                          <HorseVariant key={variant.id} variant={variant}/>
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            autoComplete='off'
                            id='minimum_order_quantity'
                            label='Minimum order quantity'
                            labelHidden
                            min={1}
                            name='Minimum order quantity'
                            onChange={(value: string, key: string) => { handleHorseVariantsChange(variant.id, softAssertNumber(value), key); }}
                            type='integer'
                            value={assertString(variant.minimum_order_quantity)}
                          />
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            autoComplete='off'
                            id='pack_size'
                            label='Pack size'
                            labelHidden
                            min={0}
                            name='Pack size'
                            onChange={(value: string, key: string) => { handleHorseVariantsChange(variant.id, softAssertNumber(value), key); }}
                            type='integer'
                            value={assertString(variant.pack_size)}
                          />
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            autoComplete='off'
                            id='supplier_sku'
                            label='Supplier SKU'
                            labelHidden
                            name='Supplier SKU'
                            onChange={(value: string, key: string) => { handleHorseVariantsChange(variant.id, value, key); }}
                            value={assertString(variant.supplier_sku)}
                          />
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            autoComplete='off'
                            id='cost'
                            label='Supplier cost'
                            labelHidden
                            min={0.00}
                            name='Supplier cost'
                            onChange={(value: string, key: string) => { handleHorseVariantsChange(variant.id, value, key); }}
                            prefix={getCurrencySymbol(supplier.currency || userCurrency)}
                            step={0.01}
                            type='currency'
                            value={assertString(variant.cost)}
                          />
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Button
                          icon={<Icon source={MobileCancelMajor} />}
                          onClick={() => { removeVariant(variant.id); }}
                          variant="plain"
                        />
                      </IndexTable.Cell>
                    </IndexTable.Row>)
                  )}
                </IndexTable>
              </LegacyCard>
          </Layout.Section>
        </Layout>
        <PageActions secondaryActions={[
          {
            content: 'Delete',
            destructive: true,
            onAction: () => { setDeletionDialogueActive(true); },
          }
        ]}/>
        <DeleteConfirmationDialog
          active={deletionDialogueActive}
          content='Are you sure you want to delete this supplier? This action cannot be reversed.'
          deleteItem={deleteSupplier}
          gotoPath='/suppliers'
          itemId={supplierId}
          setActive={setDeletionDialogueActive}
          title='Delete supplier'
        />
        <Footer pageTitle="suppliers" url={SUPPLIERS_HELP_PAGE_URL} />
      </Page>
    </>
  );

  return pageLoading ? <SupplierShowPageSkeleton /> : pageMarkup;
}

export default ShowSupplier;

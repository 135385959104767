import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Provider } from "@shopify/app-bridge-react";

export function AppBridgeProvider({
  children
}: {
  readonly children: React.ReactNode
}): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const history = useMemo(
    () => ({
      replace: navigate,
    }),
    [navigate],
  );

  const routerConfig = useMemo(() => ({ history, location }), [history, location]);

  // The host may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const appBridgeConfig = useMemo(() => {
    const body: Element | undefined = document.getElementsByTagName('body')[0];
    const host: string = new URLSearchParams(location.search).get("host") || body?.getAttribute("data-host") || "";
    const apiKey = body?.getAttribute("data-api-key");

    return {
      host,
      apiKey,
      forceRedirect: true,
    };
  },[]);

  return (
    <Provider config={appBridgeConfig} router={routerConfig}>
      {children}
    </Provider>
  );
}

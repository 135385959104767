import React, { useCallback, useState } from 'react';
import {
  Modal,
  DropZone,
  LegacyStack,
  Thumbnail,
  Text,
} from '@shopify/polaris';
import {
  NoteMinor,
} from '@shopify/polaris-icons';


export function ImportFile({
  title,
  setErrorMessage,
  setToastMessage,
  modalIsOpen,
  setModalIsOpen,
  apiCallMethod,
  setApiResponse,
}: {
  readonly title: string,
  readonly setErrorMessage: (message: string) => void,
  readonly setToastMessage: (message: string) => void,
  readonly modalIsOpen: boolean,
  readonly setModalIsOpen: (isOpen: boolean) => void,
  readonly apiCallMethod: (formData: FormData) => Promise<{message: string, data: any}>,
  readonly setApiResponse: (data: any) => void,
}): React.JSX.Element {
  const acceptedType = "text/csv";
  const [file, setFile] = useState<File>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[]): void => {
      setFile(acceptedFiles[0]);
    },
    [setFile],
  );

  const handleSave = useCallback((): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('file', file);

    const apiRes = apiCallMethod(formData);

    apiRes.then((res) => {
      setToastMessage(res.message);
      if (setApiResponse) setApiResponse(res.data);
      setModalIsOpen(false);
      setFile(null);
    }).catch((err) => {
      Rollbar.error(err);
      const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
      setErrorMessage(errorMessage);
      setModalIsOpen(false);
      setFile(null);
    }).finally(() => {
      setIsSaving(false);
    });
  }, [file]);

  const fileAttachMarkup = !file && <DropZone.FileUpload />;
  const uploadedFileMarkup = file && (
    <div style={{ padding: '10%' }}>
      <LegacyStack alignment="center" distribution="center">
        <Thumbnail
          alt={file.name}
          size="small"
          source={NoteMinor}
        />
        <div>
          {file.name} <Text as="p" variant="bodySm">{file.size} bytes</Text>
        </div>
      </LegacyStack>
    </div>
  );

  const handleClose = useCallback(() => {
    setModalIsOpen(false);
    setFile(null);
  }, [
    setModalIsOpen,
    setFile,
  ]);

  return (
    <Modal
      onClose={handleClose}
      open={modalIsOpen}
      primaryAction={{
        loading: isSaving,
        content: 'Import',
        onAction: handleSave,
        disabled: !file || isSaving,
      }}
      title={title || 'Import file'}
    >
      <Modal.Section>
        <DropZone accept={acceptedType} allowMultiple={false} onDrop={handleDropZoneDrop}>
          {uploadedFileMarkup}
          <br />
          {fileAttachMarkup}
        </DropZone>
      </Modal.Section>
    </Modal>
  );
}

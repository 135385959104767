import React from 'react';
import {
  LegacyCard,
  LegacyStack,
  RadioButton,
  List,
} from '@shopify/polaris';

export const SyncbackOptions = ({ syncbackSetting, handleSyncOptionChange }: {
  syncbackSetting: string,
  handleSyncOptionChange: (newValue: boolean, id: string) => void,
}): React.JSX.Element => {
  return (<LegacyCard>
    <LegacyCard.Header title={"Select sync-back mode"}/>
    <LegacyCard.Section>
      What should Horse sync back to Shopify?
    </LegacyCard.Section>
    <LegacyCard.Section>
      <LegacyStack vertical>
        <RadioButton
          label="Always ask"
          helpText={
            <List type="bullet">
              <List.Item>
                Horse will sync all values from Shopify, include variant price, cost, weight, etc.
              </List.Item>
              <List.Item>
                Horse will always prompt before syncing anything back to Shopify.
              </List.Item>
            </List>
          }
          checked={syncbackSetting === 'disable_syncback'}
          id="disable_syncback"
          onChange={handleSyncOptionChange}
        />
        <RadioButton
          label="Cost"
          helpText={
            <List type="bullet">
              <List.Item>
                Horse will sync all values from Shopify, include variant price, cost, weight, etc.
              </List.Item>
              <List.Item>
                When you edit the cost of a variant in Horse, it will automatically be updated in Shopify.
              </List.Item>
            </List>
          }
          id="only_cost"
          checked={syncbackSetting === 'only_cost'}
          onChange={handleSyncOptionChange}
        />
      </LegacyStack>
    </LegacyCard.Section>
  </LegacyCard>
  );
};

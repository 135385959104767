import React, { useState, useEffect } from 'react';
import {
  Banner,
  ProgressBar,
} from '@shopify/polaris';
import { getReadBanner, createReadBanner } from '../../../api_utils/requests';

export default function ImportProgressBanner() {
  const [alreadySeen, setAlreadySeen] = useState(true);
  useEffect(() => {
    const fetchBanner = async () => {
      if (!gon.flash || !gon.flash.info) {
        return;
      }
  
      getReadBanner("store_importing").catch((e) => {
        if (e.status === 404) {
          setAlreadySeen(false);
        }
      });
    };
    fetchBanner();
  }, []);

  const dismissBanner = () => {
    setAlreadySeen(true)
    createReadBanner("store_importing");
  };

  return !alreadySeen && gon.flash && gon.flash.info ? (
    <>
      <Banner
        status='info'
        title={ gon.flash.info }
        onDismiss={dismissBanner}
        >
          <p>Full functionality will be ready when it's done.</p>
          <p>This can take some time if your store has a lot of products or sales.</p>
          {gon.import_progress && <ProgressBar progress={gon.import_progress} />}
      </Banner>
      <br/>
    </>
  ) : <></>;
};

import type {
  Bundle,
} from "../../api_utils/types";
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Card,
  EmptyState,
  IndexTable,
  Layout,
  Link,
  Page,
  SkeletonBodyText,
} from '@shopify/polaris';
import { getBundles } from '../../api_utils/requests';
import { Footer } from '../common/Footer';
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";

const columnNames: NonEmptyArray<IndexTableHeading> = [
  { title: 'Name' },
];
const bundlesHelpPageUrl = "https://horse-inventory.notion.site/Bundles-88ac584381a445de890d4f68870d5920";
const layoutSkeleton = (
  <Card>
    <SkeletonBodyText lines={5} />
  </Card>
);

function BundlesIndex() {
  const [loading, setLoading] = useState(true);
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrev, setHasPrev] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [pageNum, setPageNum] = useState(Number(urlParams.get('page')) || 1);

  useEffect(() => {
    getBundles({ page: pageNum }).then(({ rows, hasNext, hasPrev }) => {
      setBundles(rows);
      setHasNext(hasNext);
      setHasPrev(hasPrev);
      setLoading(false);
    });
  }, [pageNum]);

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setLoading(true);
    setPageNum(newPageNum);
    getBundles({ page: newPageNum }).then(({rows, hasNext, hasPrev}) => {
      setBundles(rows);
      setHasNext(hasNext);
      setHasPrev(hasPrev);
      setLoading(false);
    });
    document.getElementsByClassName('Polaris-IndexTable__Table')[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const emptyState = (
    <EmptyState
      action={{
        content: 'Create bundle',
        url: '/bundles/new'
      }}
      heading="No bundles created yet"
      image=""
    />
  );

  const layoutMarkup = (
    <>
      <Card padding="0">
        <IndexTable
          emptyState={emptyState}
          headings={columnNames}
          itemCount={bundles.length || 0}
          pagination={{
            hasPrevious: hasPrev,
            previousKeys: [37],
            onPrevious: prevPage,
            hasNext: hasNext,
            nextKeys: [39],
            onNext: nextPage,
          }}
          resourceName={{singular: 'Bundle', plural: 'Bundles'}}
          selectable={false}
        >
          {
            bundles.map(
              (
                { id, name },
                index,
              ) => (
                <IndexTable.Row
                  id={id.toString()}
                  key={id}
                  position={index}
                >
                  <IndexTable.Cell>
                    <Link url={`/bundles/${id}`}>{name}</Link>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ),
            )
          }
        </IndexTable>
      </Card>
      <Footer pageTitle="bundles" url={bundlesHelpPageUrl} />
    </>
  );

  return (
    <Page
      primaryAction={{
        content: 'Create Bundle',
        url: "/bundles/new",
        disabled: loading,
      }}
      title='Bundles'
    >
      <Layout>
        <Layout.Section>
          {loading ? layoutSkeleton : layoutMarkup}
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default BundlesIndex;

import React from "react";
import { LegacyCard, LegacyStack, TextField, Link, Checkbox } from "@shopify/polaris";
import QueryString from "qs";
import type { HorseInventoryLevel, IHorseVariant } from "../../../../api_utils/types";

export function Quantity({
  hils,
  availableQuantities,
  setAvailableQuantities,
  horseVariant,
  setTracked,
}: {
  readonly hils: HorseInventoryLevel[];
  readonly availableQuantities: Record<number, string>;
  readonly setAvailableQuantities: (updatedAvailableQuantities: Record<number, string>) => void;
  readonly horseVariant: IHorseVariant;
  readonly setTracked: (tracked: boolean) => void;
}): JSX.Element {
  // 'hils' stands for 'HorseInventoryLevels'
  // link to 'Inventory History' tab that shows only data for this horseVariant
  const inventoryLevelHistoriesUriParams = {
    horse_variant_id: horseVariant.id,
  };
  const inventoryLevelHistoriesUri = `/inventory_level_histories?${QueryString.stringify(
    inventoryLevelHistoriesUriParams,
  )}`;

  const handleChange = (newAvailable: string, id: number): void => {
    setAvailableQuantities({ ...availableQuantities, [id]: newAvailable });
  };

  return (
    <LegacyCard sectioned>
      <div style={{ marginBottom: "10px" }}>
        <LegacyStack distribution="equalSpacing">
          <LegacyStack.Item>
            <h2 className="Polaris-Heading">Quantity</h2>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <Link url={inventoryLevelHistoriesUri}>Show history</Link>
          </LegacyStack.Item>
        </LegacyStack>
      </div>
      <LegacyCard.Section>
        <Checkbox checked={horseVariant.tracked} label="Track quantity" onChange={setTracked} />
      </LegacyCard.Section>
      {hils?.map((hil) => (
        <LegacyCard.Section key={`${hil.id}-${hil.horse_location_id}-quantity`}>
          <LegacyStack alignment="baseline" distribution="fillEvenly">
            <h4>
              <Link url={`/horse_locations/${hil.horse_location_id}`}>{hil.horse_location.name}</Link>
            </h4>
            <TextField
              autoComplete="off"
              disabled={!horseVariant.tracked}
              helpText={
                horseVariant.tracked &&
                (availableQuantities[hil.id] === undefined ||
                  availableQuantities[hil.id] === null ||
                  availableQuantities[hil.id] === "") &&
                hil.available !== null &&
                hil.available !== undefined
                  ? "Empty value will be set to untracked"
                  : undefined
              }
              inputMode="numeric"
              label=""
              labelHidden
              onChange={(newAvailable: string) => {
                handleChange(newAvailable, hil.id);
              }}
              type="integer"
              // Polaris TextField's onChange prop function automatically
              // returns changed string (i.e. newAvailable)
              value={horseVariant.tracked && availableQuantities[hil.id] ? availableQuantities[hil.id] : ""}
            />
          </LegacyStack>
        </LegacyCard.Section>
      ))}
    </LegacyCard>
  );
}

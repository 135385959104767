import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  FormLayout,
  InlineStack,
  LegacyStack,
  TextField,
  Text,
  Checkbox,
  Label,
} from "@shopify/polaris";
import { getHorseInventoryLevels, updateHorseInventoryLevels } from "../../api_utils/requests";
import { assertString, softAssertNumber } from "../../helper_functions/utils";
import Toast from "./Toast";
import type { HorseInventoryLevel } from "../../api_utils/types";

export function QuantityChangeModal({
  activatorText,
  horseVariantId,
  disabled,
  tracked,
  setErrorMessage,
}: {
  readonly activatorText: number | string;
  readonly horseVariantId: number;
  readonly disabled: boolean;
  readonly tracked: boolean;
  readonly setErrorMessage: (message: string) => void;
}): React.JSX.Element {
  const [active, setActive] = useState(false);
  const [dynamicActivatorTxt, setDynamicActivatorTxt] = useState(activatorText);
  const [hils, setHils] = useState<HorseInventoryLevel[]>([]);
  const [trackedQuantity, setTrackedQuantity] = useState(tracked);

  const [availableQuantities, setAvailableQuantities] = useState<Record<number, string>>(
    hils.reduce((map, { id, available }) => {
      map[id] = assertString(available);
      return map;
    }, {}),
  );
  useEffect(() => {
    setAvailableQuantities(
      hils.reduce((map, { id, available }) => {
        map[id] = assertString(available);
        return map;
      }, {}),
    );
  }, [hils]);

  const [toastMessage, setToastMessage] = useState<string>(null);

  const makeActive = () => {
    setActive(true);
    getHorseInventoryLevels({ horse_variant_id: horseVariantId }).then(setHils);
  };

  // Make sure that dynamicActivatorTxt is updated when parent table re-renders
  useEffect(() => {
    setDynamicActivatorTxt(activatorText);
  }, [activatorText]);

  const makeInactive = () => {
    setActive(false);
  };

  const handleChange = (newAvailable: string, id: number): void => {
    setAvailableQuantities({ ...availableQuantities, [id]: newAvailable });
  };

  const handleSave = (): void => {
    const horse_inventory_levels = hils.map(({ id, horse_location_id, horse_variant_id }) => ({
      id,
      available: softAssertNumber(availableQuantities[id]),
      horse_location_id,
      horse_variant_id,
    }));

    updateHorseInventoryLevels({
      horse_variant_id: horseVariantId,
      horse_inventory_levels,
      tracked: trackedQuantity,
    })
      .then((res) => {
        if (trackedQuantity) {
          setDynamicActivatorTxt(res.reduce((accum, hil) => accum + hil.available, 0));
        } else {
          setDynamicActivatorTxt("∞");
        }
        setToastMessage("Quantity updated");
      })
      .catch((err) => {
        Rollbar.error(err);
        const errorMessage: string = err?.errors?.join("\n") || err.message || err.statusText || err.status.toString();
        setErrorMessage(errorMessage);
      });
    makeInactive();
  };

  const activator = (
    <Button disabled={disabled} onClick={makeActive} variant="plain">
      {String(dynamicActivatorTxt)}
    </Button>
  );

  const rows = hils.map((hil) => {
    return (
      <InlineStack key={`row-${hil.horse_location_id}-${hil.horse_variant_id}`}>
        <div style={{ flexGrow: 1 }}>
          <Label id={`${hil.horse_location_id}-label`} key={`${hil.horse_location_id}-label`}>
            {hil.horse_location.name}
          </Label>
        </div>
        <TextField
          autoComplete="off"
          disabled={!trackedQuantity}
          helpText={
            trackedQuantity &&
            (availableQuantities[hil.id] === undefined ||
              availableQuantities[hil.id] === null ||
              availableQuantities[hil.id] === "") &&
            hil.available !== null &&
            hil.available !== undefined
              ? "Empty value will be set to untracked"
              : undefined
          }
          inputMode="numeric"
          key={`field-${hil.horse_location_id}-${hil.horse_variant_id}`}
          label={hil.horse_location.name}
          labelHidden
          onChange={(newAvailable: string) => {
            handleChange(newAvailable, hil.id);
          }}
          selectTextOnFocus
          type="integer"
          value={trackedQuantity && availableQuantities[hil.id] ? availableQuantities[hil.id] : ""}
        />
      </InlineStack>
    );
  });

  return (
    <>
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <Modal
        activator={activator}
        onClose={makeInactive}
        open={active}
        primaryAction={{
          content: "Save",
          onAction: handleSave,
        }}
        title="Change quantity"
      >
        <Modal.Section>
          <FormLayout key={`horse-variants_hil-${horseVariantId}-quantity-change-form`}>
            {hils.length > 0 && (
              <FormLayout.Group>
                <LegacyStack alignment="baseline" distribution="fillEvenly">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox checked={trackedQuantity} label="Track quantity" onChange={setTrackedQuantity} />
                  </div>
                </LegacyStack>
              </FormLayout.Group>
            )}
            {hils.length === 0 ? (
              <Text as="p" variant="bodySm">
                No inventory levels found
              </Text>
            ) : (
              rows
            )}
          </FormLayout>
        </Modal.Section>
      </Modal>
    </>
  );
}

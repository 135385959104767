import React, { useState, useCallback } from "react";
import { Modal, Button } from "@shopify/polaris";
import ForecastingForm from "./ForecastingForm";
import type { DateRangeFilter, QueryParams } from "../../../api_utils/types";

export default function AutoAddVariantDialog({
  arrivalAndDepletionDate,
  autoAdd,
  handleFormFieldChange,
  salesChannelIds,
  productTypesFilter,
  selectedForecastingMethod,
  selectedSamplingPeriod,
}: {
  readonly arrivalAndDepletionDate: DateRangeFilter;
  readonly autoAdd: (query: QueryParams) => void;
  readonly handleFormFieldChange: (field: number, value: any) => void;
  readonly salesChannelIds: string[];
  readonly productTypesFilter: { vendor: string; supplier_id: number };
  readonly selectedForecastingMethod: string;
  readonly selectedSamplingPeriod: number;
}): JSX.Element {
  const [active, setActive] = useState(false);
  const open = useCallback(() => {
    setActive(true);
  }, []);
  const close = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <Modal
      activator={
        <Button onClick={open} variant="primary">
          Auto-add variants
        </Button>
      }
      onClose={close}
      open={active}
      title="Auto-add variants"
    >
      <Modal.Section>
        <ForecastingForm
          arrivalAndDepletionDate={arrivalAndDepletionDate}
          autoAdd={autoAdd}
          close={close}
          handleFormFieldChange={handleFormFieldChange}
          productTypesFilter={productTypesFilter}
          salesChannelIds={salesChannelIds}
          selectedForecastingMethod={selectedForecastingMethod}
          selectedSamplingPeriod={selectedSamplingPeriod}
        />
      </Modal.Section>
    </Modal>
  );
}

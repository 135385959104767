import React, { useState, useCallback } from "react";
import { Select, LegacyCard, Page, Layout, FormLayout } from "@shopify/polaris";
import { ContextualSaveBar, Loading } from "@shopify/app-bridge-react";
import {
  createPurchaseOrder,
  useHorseLocationsOptions,
  useHorseVariantVendorsOptions,
  useSuppliersSearchOptions,
} from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import Toast from "../common/Toast";
import { useNavigate } from "react-router-dom";
import { extractMessageFromError, softAssertNumber } from "../../helper_functions/utils";
import type { CreatePurchaseOrder } from "../../api_utils/types";
const purchaseOrderHelpPageUrl =
  "https://horse-inventory.notion.site/Purchase-order-page-2852aa16b2f44fa4bca5da88d787df89";

export default function Index(): React.ReactElement {
  const navigate = useNavigate();

  const { data: vendors } = useHorseVariantVendorsOptions({ omitAll: true });
  const { data: suppliers } = useSuppliersSearchOptions({ omitAll: true });
  const { data: horseLocations } = useHorseLocationsOptions({ omitAll: true });

  const formattedSuppliers = suppliers.map((supplierOption) => ({
    label: `Supplier - ${supplierOption.label}`,
    value: `supplier:${supplierOption.value}`,
  }));
  const emptyLabel = "-";
  const blankOption = { label: emptyLabel, value: undefined };
  const formattedVendors = vendors.map((vendorOption) => ({
    label: `Vendor - ${vendorOption.label}`,
    value: `vendor:${vendorOption.value}`,
  }));
  const vendorsAndSuppliers = formattedSuppliers.concat([blankOption]).concat(formattedVendors);

  const [newPurchaseOrder, setNewPurchaseOrder] = useState<{
    vendorOrSupplier: string;
    horse_location_id: number;
  }>({
    vendorOrSupplier: undefined,
    horse_location_id: undefined,
  });

  const selectedVendorOrSupplier: string | undefined =
    newPurchaseOrder.vendorOrSupplier || formattedSuppliers[0]?.value;
  const selectedHorseLocationId: number | undefined = softAssertNumber(
    newPurchaseOrder.horse_location_id || horseLocations[0]?.value,
  );

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  const [loading, setLoading] = useState(false);

  const handleSelectChange = useCallback(
    (value: string, id: "vendorOrSupplier" | "horse_location_id") => {
      if (value && value !== emptyLabel) {
        setNewPurchaseOrder({ ...newPurchaseOrder, [id]: value });
      }
    },
    [newPurchaseOrder],
  );

  const handleSavePurchaseOrder = (): void => {
    setLoading(true);
    let purchaseOrder: CreatePurchaseOrder;
    const [vendorOrSupplier, value] = selectedVendorOrSupplier.split(":");
    if (vendorOrSupplier === "vendor") {
      purchaseOrder = {
        horse_location_id: selectedHorseLocationId,
        vendor: value,
      };
    } else {
      purchaseOrder = {
        horse_location_id: selectedHorseLocationId,
        supplier_id: Number(value),
      };
    }
    createPurchaseOrder({ purchase_order: purchaseOrder })
      .then((response) => {
        setToastMessage("Purchase order created");
        setLoading(false);
        navigate(`/purchase_orders/${response.id}`);
      })
      .catch((err: unknown): void => {
        Rollbar.error(err);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
        setLoading(false);
      });
  };

  const handleDiscardAction = (): void => {
    setNewPurchaseOrder({
      vendorOrSupplier: undefined,
      horse_location_id: undefined,
    });
  };

  const saveVisible = !!selectedVendorOrSupplier && !!selectedHorseLocationId;

  return (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{ onAction: handleDiscardAction }}
        leaveConfirmationDisable
        saveAction={{ onAction: handleSavePurchaseOrder }}
        visible={saveVisible}
      />
      <Page
        backAction={{
          content: "Purchase orders",
          url: "/purchase_orders",
        }}
        narrowWidth
        title="Create purchase order"
      >
        <Layout>
          <Layout.Section>
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
          <Layout.Section>
            <LegacyCard>
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      helpText="Select the vendor or supplier from whom you will buy your variants."
                      id="vendorOrSupplier"
                      label="Vendor or supplier"
                      onChange={handleSelectChange}
                      options={vendorsAndSuppliers}
                      value={selectedVendorOrSupplier}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      helpText="Select the location that the variants will be shipped to."
                      id="horse_location_id"
                      label="Destination"
                      onChange={handleSelectChange}
                      options={horseLocations}
                      value={selectedHorseLocationId?.toString()}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
        <Footer pageTitle="purchase orders" url={purchaseOrderHelpPageUrl} />
        <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      </Page>
    </>
  );
}

import React, { useState } from 'react';
import {
  Form,
  FormLayout,
  TextField,
  Button,
  LegacyCard,
  ResourceList,
  ResourceItem,
  Text,
  Link,
  LegacyStack,
  TextContainer,
} from '@shopify/polaris';
import { Redirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from '@shopify/app-bridge-react';
import { saveCombineStore, updateUser } from '../../../api_utils/requests';
import { ConnectStoreModal } from './ConnectStoreModal';
import type { ShopifyShop } from '../../../api_utils/types';

export function NewShopForm({
  currentUser, shopifyShops, setErrorMessage,
}: {
  readonly currentUser: { id: number, syncLevel: string },
  readonly shopifyShops: ShopifyShop[],
  readonly setErrorMessage: (errorMessage: string) => void,
}): React.JSX.Element {
  // Navigation
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const [syncModalActive, setSyncModalActive] = useState(false);
  const [addedShopUrl, setAddedShopUrl] = useState('');

  const connectStore = (): void => {
    saveCombineStore({
      add_store: addedShopUrl,
    }).then((data) => {
      const new_store = data.add_store;
      redirect.dispatch(Redirect.Action.REMOTE, {
        url: `${window.location.origin}/login?shop=${new_store}`,
        newContext: true,
      });
    }).catch((err) => {
      Rollbar.error(err);
      const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
      setErrorMessage(errorMessage);
    });
  };

  const checkUserSyncLevel = (event: React.FormEvent<HTMLFormElement>): void => {
    if (currentUser.syncLevel === 'hybrid_sync') {
      event.preventDefault();
      setSyncModalActive(true);
    } else {
      connectStore();
    }
  };

  const renderShopifyShop = (shopifyShop: ShopifyShop, id: string): React.ReactElement => (
    <ResourceItem
      accessibilityLabel={`Visit ${shopifyShop.shopify_domain}'s dashboard`}
      external
      id={id}
      url={`https://${shopifyShop.shopify_domain}/admin`}
    >
      <LegacyStack distribution="equalSpacing">
        <LegacyStack.Item>
          <h4>
            <Text as="strong">
              <Link
                className='blue-link'
                monochrome
                removeUnderline
                target="_blank">
                {shopifyShop.name}
              </Link>
            </Text>
          </h4>
          <Text tone="subdued">
            {shopifyShop.shopify_domain}
          </Text>
        </LegacyStack.Item>
      </LegacyStack>
    </ResourceItem>
  );

  const handleEnableFullSync = (): void => {
    updateUser({
      sync_level: 'full_sync',
    }, currentUser.id).then(() => {
      setSyncModalActive(false);
      connectStore();
    }).catch((err) => {
      Rollbar.error(err);
      const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
      setErrorMessage(errorMessage);
    });
  };

  return (
    <>
      <ConnectStoreModal
          active={syncModalActive}
          handleEnableFullSync={handleEnableFullSync}
          setActive={setSyncModalActive}
        />
      <LegacyCard>
        <LegacyCard.Header title="Connect another store"/>
        <LegacyCard.Section>
          <LegacyStack spacing="baseTight" vertical>
            <Form
              action='/combine_stores'
              autoComplete
              method='POST'
              noValidate
              onSubmit={checkUserSyncLevel}
            >
              <div className='settings-form-container'>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      connectedRight={
                        <Button disabled={addedShopUrl.length < 3} primary size="large" submit>
                          Connect
                        </Button>
                      }
                      helpText={
                        <TextContainer>
                          If you receive the error &quot;Unable to access ...&quot;,
                          please <Link
                            monochrome
                            target="_blank"
                            url="https://accounts.shopify.com/store-login"
                          >login</Link> to the store you are trying to connect to, and try again.
                        </TextContainer>
                      }
                      inputMode="url"
                      label="Shopify store domain name"
                      name="add_store"
                      onChange={setAddedShopUrl}
                      placeholder="my-store.myshopify.com"
                      type="url"
                      value={addedShopUrl}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </div>
            </Form>
          </LegacyStack>
        </LegacyCard.Section>
        <ResourceList
          headerContent="Connected stores"
          items={shopifyShops}
          renderItem={renderShopifyShop}
          resourceName={{ singular: 'shop', plural: 'shops' }}
          showHeader
        />
      </LegacyCard>
    </>
  );
}

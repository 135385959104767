import React, { useEffect, useState, useCallback } from 'react';
import {
  Select,
  LegacyCard,
  Page,
  Layout,
  FormLayout,
} from '@shopify/polaris';
import { ContextualSaveBar } from '@shopify/app-bridge-react';
import { createTransferOrder, useHorseLocationsOptions } from '../../api_utils/requests';
import { ErrorBanner } from '../common/ErrorBanner';
import { Footer } from '../common/Footer';
import { PurchaseOrderNewPageSkeleton } from '../common/skeletons';
import { assertNumber } from '../../helper_functions/utils';
import Toast from '../common/Toast';
import { useNavigate } from 'react-router-dom';

function Index() {
  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);

  const [toastMessage, setToastMessage] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { data: horseLocationOptions, isLoading: pageLoading } = useHorseLocationsOptions({ omitAll: true });
  const [newTransferOrder, setNewTransferOrder] = useState<{destinationId: number, originId: number}>({
    destinationId: undefined,
    originId: undefined
  });
  useEffect(() => {
    if (horseLocationOptions.length > 0) {
      const [firstOption] = horseLocationOptions;
      setNewTransferOrder({
        destinationId: assertNumber(firstOption.value),
        originId: assertNumber(firstOption.value),
      });
    }
  }, [horseLocationOptions]);

  const handleSelectChange = useCallback((value, id) => {
    setNewTransferOrder({ ...newTransferOrder, [id]: value });
    setIsDirty(true);
  }, [newTransferOrder]);

  const handleSaveTransfer = () => {
    if (newTransferOrder.destinationId === newTransferOrder.originId) {
      setErrorMessage("Origin and destination can't be the same location");
      return;
    }

    const payload = {
      transfer_order: {
        destination_horse_location_id: newTransferOrder.destinationId,
        origin_horse_location_id: newTransferOrder.originId,
      },
    };

    createTransferOrder(payload)
      .then((response) => {
        setToastMessage('Transfer created');
        setIsDirty(false);
        navigate(`/transfer_orders/${response.id}`);
      })
      .catch((err) => {
        Rollbar.error(err);
        const errorMessage: string = err?.errors?.join('\n') || err.message || err.statusText || err.status.toString();
        setErrorMessage(errorMessage);
      });
  };

  const handleDiscardAction = (): void => {
    setIsDirty(false);
    if (horseLocationOptions.length > 0) {
      const [firstOption] = horseLocationOptions;
      setNewTransferOrder({
        destinationId: assertNumber(firstOption.value),
        originId: assertNumber(firstOption.value),
      });
    }
  };

  const transferOrdersHelpPageUrl = "https://horse-inventory.notion.site/Transfer-order-page-9292e156aa174172befc0007c400cf92";
  const pageMarkup = (
      <>
        <ContextualSaveBar
          discardAction={{
            onAction: handleDiscardAction,
          }}
          saveAction={{
            onAction: handleSaveTransfer,
          }}
          visible={isDirty}
        />
        <Page
          backAction={{
            content: 'Transfer orders',
            url: '/transfer_orders'
          }}
          narrowWidth
          title='Create transfer order'>
          <Layout>
            {errorMessage ? <Layout.Section variant="fullWidth">
              <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            </Layout.Section> : null}
            <Layout.Section>
              <LegacyCard>
                <LegacyCard.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <Select
                        helpText="Select the location from where you will transfer inventory from."
                        id="originId"
                        label="Origin"
                        onChange={handleSelectChange}
                        options={horseLocationOptions}
                        value={newTransferOrder.originId?.toString()}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <Select
                        helpText="Select the location to where you will transfer inventory to."
                        id="destinationId"
                        label="Destination"
                        onChange={handleSelectChange}
                        options={horseLocationOptions}
                        value={newTransferOrder.destinationId?.toString()}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </LegacyCard.Section>
              </LegacyCard>
            </Layout.Section>
          </Layout>
          <Footer pageTitle="transfer orders" url={transferOrdersHelpPageUrl} />
          <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
        </Page>
      </>
  );

  return pageLoading ? <PurchaseOrderNewPageSkeleton /> : pageMarkup;
}

export default Index;

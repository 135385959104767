import { ChoiceList, Select, LegacyStack, Button, FormLayout, Labelled } from "@shopify/polaris";
import React, { useState, useRef, useCallback } from "react";
import CustomRangePicker from "../../common/CustomDatePicker/CustomRangePicker";
import PopoverWithCheckList from "../../common/Filters/PopoverWithCheckList";
import {
  allTypes,
  useHorseVariantsProductTypeOptions,
  useShopifyChannelAvailabilityOptions,
  useShopifyChannelSalesOptions,
} from "../../../api_utils/requests";
import { KEYS } from "./utils";
import type { DateRangeFilter, QueryParams } from "../../../api_utils/types";

const samplingPeriods = [
  { label: "Past 30 Days", value: "30" },
  { label: "Past 90 Days", value: "90" },
  { label: "Past 180 Days", value: "180" },
  { label: "Past 365 Days", value: "365" },
];

const forecastingMethods = [
  { label: "Seasonal", value: "seasonal" },
  { label: "Sales rate", value: "sales_rate" },
];

const defaultProductTypesFilter = { vendor: null, supplier_id: null };

function ForecastingForm({
  arrivalAndDepletionDate,
  autoAdd,
  close,
  handleFormFieldChange,
  salesChannelIds,
  productTypesFilter = defaultProductTypesFilter,
  selectedForecastingMethod,
  selectedSamplingPeriod,
  hideProductAvailability = false,
  hideProductTypes = false,
}: {
  readonly arrivalAndDepletionDate: DateRangeFilter;
  readonly autoAdd?: (query: QueryParams) => void;
  readonly close?: () => void;
  readonly handleFormFieldChange: (field: number, value: any) => void;
  readonly salesChannelIds: string[];
  readonly productTypesFilter?: { vendor: string; supplier_id: number };
  readonly selectedForecastingMethod: string;
  readonly selectedSamplingPeriod: number;
  readonly hideProductAvailability?: boolean;
  readonly hideProductTypes?: boolean;
}): React.ReactElement {
  // const [salesShopifyChannels, setSalesShopifyChannels] = useState<Option[]>([]);
  const { data: salesShopifyChannels } = useShopifyChannelSalesOptions();
  const { data: availableShopifyChannels } = useShopifyChannelAvailabilityOptions();
  const [productType, setProductType] = useState<string>();
  const { data: productTypes } = useHorseVariantsProductTypeOptions({
    ...productTypesFilter,
    collection: "undiscarded",
  });

  const [productAvailability, setProductAvailability] = useState<string>();

  const getQuery = useCallback((): QueryParams => {
    const query: QueryParams = { minimum_needed: "" };
    if (productType) {
      query.product_type = productType;
    }
    const shopifyChannelOption = availableShopifyChannels.find((channel) => channel.value === productAvailability);
    query["available[]"] =
      shopifyChannelOption?.type === "available" ? shopifyChannelOption.shopifyChannelId : undefined;
    query["unavailable[]"] =
      shopifyChannelOption?.type === "unavailable" ? shopifyChannelOption.shopifyChannelId : undefined;
    if (selectedSamplingPeriod) {
      query.sampling_period = selectedSamplingPeriod;
    }
    if (selectedForecastingMethod) {
      query.forecasting_method = selectedForecastingMethod;
    }
    if (salesChannelIds) {
      query["sales_channel_ids[]"] = salesChannelIds;
    }
    if (arrivalAndDepletionDate) {
      query.arrivalAndDepletionDate = arrivalAndDepletionDate;
    }
    return query;
  }, [
    arrivalAndDepletionDate,
    salesChannelIds,
    productAvailability,
    productType,
    selectedForecastingMethod,
    selectedSamplingPeriod,
    availableShopifyChannels,
  ]);

  const handleForecastingModelChange = useCallback(
    ([forecasting_method]): void => {
      handleFormFieldChange(KEYS.FORECASTING_METHOD, forecasting_method);
    },
    [handleFormFieldChange],
  );
  const handleSamplingPeriodChange = useCallback(
    (sampling_period: string): void => {
      handleFormFieldChange(KEYS.SAMPLING_PERIOD, Number(sampling_period));
    },
    [handleFormFieldChange],
  );
  const handleSalesSourceSelection = useCallback(
    (shopify_channel_ids: string[]): void => {
      handleFormFieldChange(KEYS.SALES_CHANNEL_IDS, shopify_channel_ids);
    },
    [handleFormFieldChange],
  );
  const removeSalesSourceTag = useCallback(
    (tag: string): void => {
      const remaining = salesChannelIds.filter((product) => product !== tag);
      handleFormFieldChange(KEYS.SALES_CHANNEL_IDS, remaining);
    },
    [handleFormFieldChange, salesChannelIds],
  );
  const handleArrivalAndDepletionDateChange = useCallback(
    (newArrivalAndDepletionDate): void => {
      handleFormFieldChange(KEYS.ARRIVAL_AND_DEPLETION_DATE, newArrivalAndDepletionDate);
    },
    [handleFormFieldChange],
  );
  const handleProductTypeChange = useCallback(
    (product_type: string): void => {
      if (product_type === allTypes) {
        setProductType(null);
      } else {
        setProductType(product_type);
      }
    },
    [setProductType],
  );

  const handleAutoAdd = useCallback(() => {
    const query = getQuery();
    autoAdd(query);
    close();
  }, [autoAdd, close, getQuery]);

  const selectedMethod = forecastingMethods.find((item) => item.value === selectedForecastingMethod);
  const selectedPeriod = samplingPeriods.find((item) => item.value === selectedSamplingPeriod.toString());

  const firstSelectableDate = useRef(new Date());

  return (
    <LegacyStack vertical>
      <FormLayout>
        <FormLayout.Group>
          <ChoiceList
            allowMultiple={false}
            choices={forecastingMethods}
            onChange={handleForecastingModelChange}
            selected={[selectedMethod.value]}
            title="Select a forecasting model"
          />
        </FormLayout.Group>

        <FormLayout.Group condensed>
          {selectedForecastingMethod !== "seasonal" && (
            <Select
              helpText="How far back to look for sales data."
              label="Sampling period"
              onChange={handleSamplingPeriodChange}
              options={samplingPeriods}
              value={selectedPeriod.value.toString()}
            />
          )}
        </FormLayout.Group>
        <CustomRangePicker
          dateRange={arrivalAndDepletionDate}
          firstSelectableDate={firstSelectableDate.current}
          leftHelpText="The date that you estimate this purchase order will arrive."
          leftLabel="Arrival date"
          rightHelpText="The date that you'd like this purchase order to last until."
          rightLabel="Depletion date"
          setDateRange={handleArrivalAndDepletionDateChange}
          tooltipContent="How long you'd like the inventory to last for.
              For example, buy enough to last for 90 days."
        />
        {!hideProductTypes && (
          <Select
            id="productType"
            label="Product type"
            onChange={handleProductTypeChange}
            options={productTypes}
            value={productType}
          />
        )}
        {!hideProductAvailability && (
          <Labelled
            helpText="Only recommend products currently published to these sales channels."
            id="select-publications"
            label="Select published channels"
          >
            <PopoverWithCheckList
              allowMultiple={false}
              choices={availableShopifyChannels}
              defaultTitle="Product publishing"
              onChange={([value]) => {
                setProductAvailability(value);
              }}
              onValueRemoved={() => {
                setProductAvailability(undefined);
              }}
              selected={productAvailability ? [productAvailability] : []}
            />
          </Labelled>
        )}

        <Labelled
          helpText="Only use sales from certain sales channels."
          id="select-sales-sources"
          label="Select sales channels"
        >
          <PopoverWithCheckList
            choices={salesShopifyChannels}
            defaultTitle="Sales channels"
            onChange={handleSalesSourceSelection}
            onValueRemoved={removeSalesSourceTag}
            selected={salesChannelIds}
          />
        </Labelled>
      </FormLayout>

      {close ? (
        <LegacyStack alignment="center" distribution="center">
          <Button onClick={close}>Cancel</Button>
          <Button onClick={handleAutoAdd} variant="primary">
            Auto-Add
          </Button>
        </LegacyStack>
      ) : null}
    </LegacyStack>
  );
}

export default ForecastingForm;

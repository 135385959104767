import React from "react";
import { LegacyCard, EmptyState, LegacyStack } from "@shopify/polaris";

function EmptyStateComponent({
  title,
  header,
  infoMessage,
  imageSrc,
  seeMoreUrl,
}: {
  readonly title: string;
  readonly header: string;
  readonly infoMessage: string;
  readonly imageSrc: string;
  readonly seeMoreUrl: string;
}): React.JSX.Element {
  return (
    <LegacyCard
      secondaryFooterActions={[
        {
          content: "See more",
          plain: true,
          url: seeMoreUrl,
        },
      ]}
      title={title}
    >
      <LegacyCard.Section>
        <LegacyStack alignment="fill" distribution="center">
          <img height={187} src={imageSrc} />
        </LegacyStack>
        <EmptyState heading={header} image="">
          {infoMessage}
        </EmptyState>
      </LegacyCard.Section>
    </LegacyCard>
  );
}

export default EmptyStateComponent;

import React from 'react';
import {
  Text,
  InlineStack,
  Box,
  Button,
  Badge,
  BlockStack,
  useBreakpoints,
} from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';

const SettingToggle = ({
  enabled,
  handleToggle,
  title,
  helpLink,
  description,
}: {
  enabled: boolean,
  handleToggle: () => void,
  title: string,
  helpLink?: string,
  description: string,
}) => {
  const contentStatus = enabled ? 'Turn off' : 'Turn on';

  const toggleId = 'setting-toggle-uuid';
  const descriptionId = 'setting-toggle-description-uuid';

  const {mdDown} = useBreakpoints();

  const badgeStatus = enabled ? 'success' : undefined;

  const badgeContent = enabled ? 'On' : 'Off';

  const settingStatusMarkup = (
    <Badge
      tone={badgeStatus}
      toneAndProgressLabelOverride={`Setting is ${badgeContent}`}
    >
      {badgeContent}
    </Badge>
  );

  const helpLinkMarkup = helpLink ? (
    <Button
      variant="plain"
      icon={CircleInformationMajor}
      accessibilityLabel="Learn more"
      url={helpLink}
      external={true}
    />
  ) : null;

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false}>
      <InlineStack gap="200" align="start" blockAlign="baseline">
        <label htmlFor={toggleId}>
          <Text variant="headingMd" as="h6">
            {title}
          </Text>
        </label>
        <InlineStack gap="200" align="center" blockAlign="center">
          {settingStatusMarkup}
          {helpLinkMarkup}
        </InlineStack>
      </InlineStack>
    </InlineStack>
  ) : null;

  const actionMarkup = (
    <Button
      role="switch"
      id={toggleId}
      ariaChecked={enabled ? 'true' : 'false'}
      onClick={handleToggle}
      size="slim"
    >
      {contentStatus}
    </Button>
  );

  const headerMarkup = (
    <Box width="100%">
      <InlineStack
        gap="1200"
        align="space-between"
        blockAlign="start"
        wrap={false}
      >
        {settingTitle}
        {!mdDown ? (
          <Box minWidth="fit-content">
            <InlineStack align="end">{actionMarkup}</InlineStack>
          </Box>
        ) : null}
      </InlineStack>
    </Box>
  );

  const descriptionMarkup = (
    <BlockStack gap="400">
      <Text id={descriptionId} variant="bodyMd" as="p" tone="subdued">
        {description}
      </Text>
      {mdDown ? (
        <Box width="100%">
          <InlineStack align="start">{actionMarkup}</InlineStack>
        </Box>
      ) : null}
    </BlockStack>
  );

  return (
    <Box width="100%">
      <BlockStack gap={{xs: '200', sm: '400'}}>
        {headerMarkup}
        {descriptionMarkup}
      </BlockStack>
    </Box>
  );
};

export default SettingToggle;
import React, {
  useState, useCallback,
} from 'react';
import {
  Page,
  LegacyCard,
  IndexTable,
  Layout,
  InlineStack,
} from '@shopify/polaris';
import {
  ImportMinor,
  ExportMinor,
} from '@shopify/polaris-icons';
import qs from 'query-string';
import { TimeMachineModal } from './support/TimeMachineModal';
import { ImportFile } from '../../common/ImportFile';
import HorseVariantSearchFieldAndMoreFilters from './support/HorseVariantSearchFieldAndMoreFilters';
import { importHorseVariants, downloadFile, useUser, useHorseVariants, useHorseVariantsTotals } from '../../../api_utils/requests';
import { formatMoney, commaDelimiter, softAssertNumber } from '../../../helper_functions/utils';
import { ErrorBanner } from '../../common/ErrorBanner';
import { Footer } from '../../common/Footer';
import { HorseLocationSelect } from '../../common/Filters/HorseLocationSelect';
import { HorseVariantsIndexPageSkeleton } from '../../common/skeletons';
import type { QueryParams } from '../../../api_utils/types';
import type { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import type { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { HorseVariantRow } from '../../common/HorseVariant/HorseVariantRow';
import Toast from '../../common/Toast';
import { useNavigate } from 'react-router-dom';

const columnNames = [
  { title: 'Product title' },
  { title: 'Variant title' },
  { title: 'SKU' },
  { title: 'Vendor' },
  { title: 'Product type' },
  { title: 'Available' },
  { title: 'Weight' },
  { title: 'Cost' },
  { title: 'Value' },
] satisfies NonEmptyArray<IndexTableHeading>;

const TABS = {
  UNDISCARDED: 0,
  ALL: 1,
};

const COLLECTIONS = {
  [TABS.UNDISCARDED]: 'undiscarded',
  [TABS.ALL]: 'all',
}

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' } satisfies Intl.DateTimeFormatOptions;

const horseVariantHelpPageUrl = "https://horse-inventory.notion.site/Variants-directory-da6d069d1f6e4755a91c1dd95c04b303";

function HorseVariants(): React.ReactElement {
  const navigate = useNavigate();
  // Errors
  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);

  const parsedUrlSearch = qs.parse(window.location.search);
  const [filters, setFilters] = useState<QueryParams>(parsedUrlSearch);
  const [pageNum, setPageNum] = useState(Number(parsedUrlSearch.page) || 1);
  const [collection, setCollection] = useState<number>(softAssertNumber(parsedUrlSearch.collection as string) || TABS.UNDISCARDED);
  const [date, setDate] = useState<Date | undefined>(parsedUrlSearch.date ? new Date(parsedUrlSearch.date as string) : undefined);
  const pageTitle = date ?
    `Inventory on ${date.toLocaleDateString(undefined, dateOptions)}` :
    'Variants';

  const [
    horseLocationId,
    setHorseLocationId,
  ] = useState<number>(softAssertNumber(parsedUrlSearch.horse_location_id as string));

  const { data: {currency: userCurrency}, isLoading: pageLoading} = useUser();

  const { data: {rows, hasNext, hasPrev}, isFetching: isFetchingHorseVariants, refetch} = useHorseVariants({
    ...filters,
    horse_location_id: horseLocationId,
    collection: COLLECTIONS[collection],
    date,
    page: pageNum,
  });

  const isLoading = isFetchingHorseVariants;

  const { data: totals} = useHorseVariantsTotals({
    ...filters,
    horse_location_id: horseLocationId,
    collection: COLLECTIONS[collection],
    date,
  });

  let secondaryActions = [];
  let actionGroups = [];
  if (date) {
    secondaryActions = [
      {
        content: 'Export CSV',
        onAction: (): void => {
          const filename = `Horse Purchase Orders.csv`;
          const queryParams = {
            ...filters,
            date,
            horse_location_id: horseLocationId,
            collection: COLLECTIONS[collection],
          };
          downloadFile('/horse_variants.csv', filename, queryParams);
        },
        external: true,
      },
    ];
  } else {
    actionGroups = [
      {
        title: 'CSV',
        actions: [
          {
            content: 'Export',
            onAction: (): void => {
              const filename = `Horse Purchase Orders.csv`;
              const queryParams = {
                ...filters,
                horse_location_id: horseLocationId,
                collection: COLLECTIONS[collection],
              };
              downloadFile('/horse_variants.csv', filename, queryParams);
            },
            icon: ExportMinor,
          },
          {
            content: 'Import',
            onAction: () => { setImportCSVModalIsOpen(true); },
            icon: ImportMinor,
          },
        ],
      },
    ];
  }

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName('Polaris-IndexTable__Table')[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const setFiltersAndResetPageNum = useCallback((newFilters: QueryParams) => {
    setFilters(newFilters);
    setPageNum(1);
  }, []);

  const setCollectionAndResetPageNum = useCallback((newCollection) => {
    setCollection(newCollection);
    setPageNum(1);
  }, []);

  const pageMarkup = (
    <Page
      // Have option to go back to variants page when on time machine
      actionGroups={actionGroups}
      backAction={!!date && {
          content: pageTitle,
          onAction: () => {
            setDate(undefined);
            setPageNum(1);
            navigate(`/horse_variants`);
          }
        }
      }
      fullWidth
      primaryAction={
        <TimeMachineModal
          setDate={(newDate: Date) => {
            setDate(newDate);
            setPageNum(1);
            navigate(`/horse_variants?date=${newDate.toISOString()}`);
          }}
        />
      }
      secondaryActions={secondaryActions}
      title={pageTitle}
    >
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <ImportFile
        apiCallMethod={importHorseVariants}
        modalIsOpen={importCSVModalIsOpen}
        setApiResponse={refetch}
        setErrorMessage={setErrorMessage}
        setModalIsOpen={setImportCSVModalIsOpen}
        setToastMessage={setToastMessage}
        title="Import variants CSV"
      />
      <Layout>
        {errorMessage ? <Layout.Section>
          <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
        </Layout.Section> : null}
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <InlineStack blockAlign='start' gap="300">
                <HorseLocationSelect horseLocationId={horseLocationId} setHorseLocationId={setHorseLocationId} />
              </InlineStack>
            </LegacyCard.Section>
            <HorseVariantSearchFieldAndMoreFilters
                horseLocationId={horseLocationId}
                onSelect={setCollectionAndResetPageNum}
                selected={collection}
                setFilters={setFiltersAndResetPageNum}
                setHorseLocationId={setHorseLocationId}
              />
            <IndexTable
              headings={columnNames}
              itemCount={rows.length}
              loading={isLoading}
              pagination={{
                hasPrevious: hasPrev,
                previousKeys: [37],
                onPrevious: prevPage,
                hasNext: hasNext,
                nextKeys: [39],
                onNext: nextPage,
              }}
              selectable={false}
            >
              <IndexTable.Row id='horseVariantsIndexTotalsRow' key={0} position={0} rowType="subheader">
                <IndexTable.Cell>
                  Totals
                </IndexTable.Cell>
                <IndexTable.Cell />
                <IndexTable.Cell />
                <IndexTable.Cell />
                <IndexTable.Cell />
                <IndexTable.Cell>
                  {commaDelimiter(totals.currently_available_quantity)}
                </IndexTable.Cell>
                <IndexTable.Cell />
                <IndexTable.Cell />
                <IndexTable.Cell>
                  {formatMoney(totals.total_value, userCurrency)}
                </IndexTable.Cell>
              </IndexTable.Row>
              {
                rows.map((horseVariant, index) => (
                  <HorseVariantRow
                    horseVariant={horseVariant}
                    key={horseVariant.id}
                    position={index + 1}
                    setErrorMessage={setErrorMessage}
                    userCurrency={userCurrency}
                  />
                ))
              }
            </IndexTable>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <Footer pageTitle="variants" url={horseVariantHelpPageUrl} />
    </Page>
  );

  return pageLoading ? <HorseVariantsIndexPageSkeleton /> : pageMarkup;
}

export default HorseVariants;

import type { ClientApplication } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { createApp } from '@shopify/app-bridge';

declare global {
  interface Window {
    sessionToken: string;
    initialSessionTokenPromise: Promise<string>;
    Rollbar: any;
    gon: Record<string, any>
  }
}

const sessionTokenEvent = new Event("sessionToken");

const SESSION_TOKEN_REFRESH_INTERVAL = 2000; // Request a new token every 2s

const maintain_token = (app: ClientApplication): void => {
  async function retrieveToken(): Promise<string> {
    const sessionToken = await getSessionToken(app);
    window.sessionToken = sessionToken;
    // console.log("TOKEN", window.sessionToken);
    document.dispatchEvent(sessionTokenEvent);
    return sessionToken;
  }

  // We must refresh the token every 2 minutes!
  setInterval(retrieveToken, SESSION_TOKEN_REFRESH_INTERVAL);

  // Return initial token retrieval
  window.initialSessionTokenPromise = retrieveToken();
};

document.addEventListener("DOMContentLoaded", () => {
  const body: Element | undefined = document.getElementsByTagName('body')[0];
  const host: string = new URLSearchParams(location.search).get("host") || body?.getAttribute("data-host");
  const apiKey = body?.getAttribute("data-api-key");

  if (!host) {
    console.error("No host provided");
    return;
  }

  const app = createApp({
    apiKey,
    host: host,
  });

  maintain_token(app);
});

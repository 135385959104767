import React from "react";
import { LegacyCard, LegacyStack, TextField } from "@shopify/polaris";
import { assertString, commaDelimiter, getCurrencySymbol, softAssertNumber } from "../../../../helper_functions/utils";
import type { IHorseVariant } from "../../../../api_utils/types";
import { useUser } from "../../../../api_utils/requests";

export function Pricing({
  cost,
  setCost,
  horseVariant,
}: {
  readonly cost: number;
  readonly setCost: (newCost: number) => void;
  readonly horseVariant: IHorseVariant;
}): JSX.Element {
  const { data: {currency: userCurrency} } = useUser();
  return (
    <LegacyCard sectioned>
      <h2 className="Polaris-Heading mb20-important">Pricing</h2>
      <LegacyStack distribution="fillEvenly">
        <TextField
          align="right"
          autoComplete="off"
          disabled
          helpText="To change the price of a variant, please edit it from your Shopify store(s)"
          label="Price"
          prefix={getCurrencySymbol(userCurrency)}
          type="number"
          value={commaDelimiter(horseVariant.price, 2)}
        />
        <TextField
          align="right"
          autoComplete="off"
          inputMode="numeric"
          label="Cost"
          onChange={(newCost) => { setCost(softAssertNumber(newCost)); }}
          prefix={getCurrencySymbol(userCurrency)}
          type="number"
          value={assertString(cost)}
        />
      </LegacyStack>
    </LegacyCard>
  );
}
